.consent-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.consent-modal {
    background-color: white;
    padding: 40px;
    border-radius: 5px;
    max-width: 800px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #000000;
    overflow-y: auto;
    max-height: 750px; /* Add this line */
}

.consent-modal-header {
    margin-bottom: 20px;
}

.consent-modal-body {
    margin-bottom: 20px;
}

.consent-modal-footer {
    text-align: right;
}

.consent-button {
    padding: 8px 15px;
    background-color: #007bff;
    color: white; /* Changed color to black */
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.consent-button:hover {
    background-color: #0056b3;
}
